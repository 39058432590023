export default {
    login(context, payload) {
        context.commit('setAuthenticated', {
            authenticated: true
        })
    },
    logout(context, payload) {
        context.commit('setAuthenticated', {
            authenticated: false
        })
    },
    updateState(context, payload) {
        context.commit('updateAuthenticatedState', {
            authenticated: payload.authenticated
        })
    }
}
