<template>
    <footer class="footer">
        <div class="site-section">
            <div class="container">
                <div class="cta d-block d-md-flex align-items-center px-5">
                    <div>
                        <h2 class="mb-0">Geïnteresseerd of vragen?</h2>
                        <h3 class="text-dark">Contacteer ons vrijblijvend via ons online formulier</h3>
                    </div>
                    <div class="ml-auto">
                        <router-link to="contact" class="btn btn-dark rounded-0 py-3 px-5">Contact</router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm border-top pt-3">
                        <a href="#" class="footer-logo">Sint Martinus Fonds</a>
                        <p class="copyright"><small>&copy; <a href="https://www.pure-y.be" class="webUrl" target="_blank">Pure-Y</a> - {{ currentYear }}</small></p>
                        <p class="copyright">Deze site gebruikt enkel technisch noodzakelijke cookies. Persoonsgegevens worden niet opgevraagd noch bewaard.</p>
                    </div>
                    <div class="col-sm border-top pt-3">
                        <h3>Quick Links</h3>
                        <ul class="list-unstyled links">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/bedelingen">Bedelingen</router-link></li>
                            <li><router-link to="/werking">Werking</router-link></li>
                            <li><router-link to="/nevenactiviteiten">Bijkomende activiteiten</router-link></li>
                            <li><router-link to="/ontstaan">Onstaan</router-link></li>
                            <li><router-link to="/doel">Doel</router-link></li>
                            <li><router-link to="/contact">Contact</router-link></li>
                        </ul>
                    </div>
                    <div class="col-sm border-top pt-3">
                        <h3>Further Information</h3>
                        <ul class="list-unstyled links">
                            <li><a href="mailto:y@pure-y.be">Contact Webmaster</a></li>
                            <li><router-link to="/beheer">Administratie</router-link></li>
                        </ul>
                    </div>
                    <!-- <div class="col-md-3">
                        <h3>Follow us</h3>
                        <ul class="list-unstyled social">
                            <li><a href="#"><span class="icon-facebook"></span></a></li>
                            <li><a href="#"><span class="icon-twitter"></span></a></li>
                            <li><a href="#"><span class="icon-linkedin"></span></a></li>
                            <li><a href="#"><span class="icon-medium"></span></a></li>
                            <li><a href="#"><span class="icon-paper-plane"></span></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    computed: {
        currentYear () {
            return new Date().getFullYear()
        }
    }
}
</script>

<style scoped>
/* footer {
    height: 200px;
    border-top: 3px groove red;
} */

.webUrl {
    color: #666873;
}
.webUrl:hover {
    color: #fff;
}

.footer {
    position: relative;
    color: #fff;
    padding-top: 1rem;
    padding-bottom: 2rem;
    margin-top: 2rem;
    background-color: #141d2a;
}
.footer .container {
    position: relative;
}
.footer h3 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 0;
    line-height: 1.5;
}
.footer .links li {
    line-height: 1.5;
    display: block;
}
.footer .links li a {
    color: #666873;
}
.footer .links li a:hover {
    color: #fff;
}
.footer .social li {
    display: inline-block;
    position: relative;
}
.footer .social li a {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    margin: 0;
    padding: 0;
    background-color: #8186d5;
    color: #fff;
}
.footer .social li a > span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.footer .footer-logo {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: .1rem;
}
.footer .copyright {
    color: #666873;
}
.footer .cta {
    /* -webkit-box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
    box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2); */
    padding: 20px;
    background-color: #8186d5;
    top: -75px;
    position: relative;
}
.footer .cta h2, .footer .cta h3 {
    line-height: 1.5;
}
.footer .cta h3 {
    font-size: 16px;
}
</style>
