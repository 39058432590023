<template>
    <div class="container-fluid bg-light">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                    <img src="@/assets/images/logo-50-rounded.jpg" alt="">
                    Sint-Martinus Fonds
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav" :class="{ 'show': toggledNav }">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item">
                            <router-link to="/" @click="toggledNav = !toggledNav" class="nav-link">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/bedelingen" @click="toggledNav = !toggledNav" class="nav-link">Bedelingen</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/werking" @click="toggledNav = !toggledNav" class="nav-link">Werking</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/nevenactiviteiten" @click="toggledNav = !toggledNav" class="nav-link">Bijkomende activiteiten</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/ontstaan" @click="toggledNav = !toggledNav" class="nav-link">Onstaan</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/doel" @click="toggledNav = !toggledNav" class="nav-link">Doel</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/contact" @click="toggledNav = !toggledNav" class="nav-link">Contact</router-link>
                        </li>
                        <li class="nav-item" v-if="isAuthenticated">
                            <button class="btn btn-sm btn-outline-secondary" @click.prevent="logout">Logout</button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_ENV_BASE_URL

export default {
    data () {
        return {
            toggledNav: false
        }
    },
    watch: {
        '$route' () {
            this.toggledNav = false
        }
    },
    computed: {
        isAuthenticated () {
            return this.$store.getters['auth/isAuthenticated']
        }
    },
    methods: {
        logout () {
            axios.post('/logout').then(response => {
                this.$store.dispatch('auth/logout')
                this.$router.replace({ name: 'Home' })
            })
        }
    }
}
</script>

<style scoped>
a {
    padding:5px
}

a:active, a:hover, a.router-link-active {
    border-bottom: 1px solid #8186d5;
    font-weight: bold;
    /* #141d2a;#8186d5 */
}

</style>
