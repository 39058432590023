<template>
    <section id="gallery" class="py-5">
        <div class="container">
        <h1 class="text-center">Een paar foto's</h1>
        <p class="text-center">Want ze zeggen meer dan 1000 woorden</p>
            <div class="row mb-4 g-2">
                <div class="col-md-4">
                    <img src="@/assets/images/gallery/image-1.jpg" alt="" class="img-fluid">
                </div>
                <div class="col-md-4">
                    <img src="@/assets/images/gallery/image-2.jpg" alt="" class="img-fluid">
                </div>
                <div class="col-md-4">
                    <img src="@/assets/images/gallery/image-3.jpg" alt="" class="img-fluid">
                </div>
                <div class="col-md-4">
                    <img src="@/assets/images/gallery/image-4.jpg" alt="" class="img-fluid">
                </div>
                <div class="col-md-4">
                    <img src="@/assets/images/gallery/image-5.jpg" alt="" class="img-fluid">
                </div>
                <div class="col-md-4">
                    <img src="@/assets/images/gallery/image-6.jpg" alt="" class="img-fluid">
                </div>
                <div class="col-md-4">
                    <img src="@/assets/images/gallery/image-7.jpg" alt="" class="img-fluid">
                </div>
                <div class="col-md-4">
                    <img src="@/assets/images/gallery/image-8.jpg" alt="" class="img-fluid">
                </div>
                <div class="col-md-4">
                    <img src="@/assets/images/gallery/image-9.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
</template>
