<template>
    <section id="showcase" class="py-5">
        <div class="primary-overlay text-white">
            <div class="container">
                <div class="row py-5">
                    <div class="col-lg-6 text-center">
                        <h1 class="display-2 mt-5 pt-5">
                            Sint-Martinus Fonds
                        </h1>
                        <hr class="line" />
                        <p class="lead">Noodfonds voor minderbedeelden</p>
                    </div>
                    <div class="col-lg-6 justify-content-center">
                        <img src="@/assets/images/banner-logo-colored.jpg" alt="" class="img-fluid d-none d-lg-block mx-auto rounded pt-2">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
#showcase {
    position: relative;
    background: url('~@/assets/images/Berlare_1.jpg');
    min-height: 480px;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    border-bottom: 2px groove #141d2a;
}

#showcase .primary-overlay {
    /* background: rgba(129, 134, 213, 0.8); */
    background: rgba(20, 29, 42, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.info-header {
  width: 50%;
  margin: auto;
  border-bottom: 1px #ddd solid;
}

.line {
    color: red;
}

@media (max-width: 991.98px) {
    #showcase {
        min-height: 400px;
    }

    #showcase h1 {
        font-size: 4rem;
    }

    .mb-resp {
        margin-bottom: 1rem;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    #showcase {
        min-height: 500px;
    }

    #showcase h1 {
        font-size: 4rem;
    }

    .mb-resp {
        margin-bottom: 1rem;
    }
}
</style>
