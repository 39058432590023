import { createStore } from 'vuex'

import momentsModule from './modules/moments/index'
import authModule from './modules/auth/index'

const store = createStore({
    modules: {
        moments: momentsModule,
        auth: authModule
    }
})

export default store
