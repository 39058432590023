import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faAddressCard, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

library.add(faUserSecret, faAddressCard, faBars)

const application = createApp(App)
application.component('font-awesome-icon', FontAwesomeIcon)

application.use(store).use(router)
application.mount('#app')
