<template>
    <div class="page-wrapper">
        <TheHeader />
        <router-view v-slot="slotProps">
            <transition name="route" mode="out-in">
                <component :is="slotProps.Component"></component>
            </transition>
        </router-view>
        <TheFooter />
    </div>
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue'
import TheFooter from './components/layout/TheFooter'

export default {
    components: {
        TheHeader,
        TheFooter
    },
    created() {
        this.checkLogin()
    },
    methods: {
        checkLogin () {
            if (localStorage.getItem('authenticated') === 'true') {
                this.$store.dispatch('auth/updateState', {
                    authenticated: true
                })
            }
        }
    }
}
</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
}

.route-enter-from {
    opacity: 0;
    transform: translateX(-30px);
}
.route-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.route-enter-active{
    transition: all 0.3s ease-out;
}
.route-leave-active {
    transition: all 0.3s ease-in;
}

.route-enter-to
.route-leave-from {
    opacity: 1;
    transform: translateX(0);
}

</style>
