<template>
    <div>
        <TheBanner />
        <section id="home-content">
            <div class="container pt-5">
                <div class="row">
                    <div class="col-12">
                        <div class="card text-center border mb-resp">
                            <div class="card-header text-white d-flex flex-column justify-content-center align-items-center">
                                <h2>Even in het kort ...</h2>
                            </div>
                            <div class="card-body">
                                <p class="pt-2">Het Sint-Martinusfonds is een noodfonds voor minderbedeelden: alleenstaanden, gezinnen, verslaafden, daklozen, vluchtelingen, …. Huidskleur noch ras of taal spelen hier een rol.
                                    Het zijn mensen die -al dan niet tijdelijk- nood hebben aan wat bijkomende steun.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pt-1 pb-3">
                <div class="row">
                    <div class="card-group">
                        <div class="card border">
                            <div class="card-body mr-1">
                                <p>Enerzijds gaat het om een materiële ondersteuning van de dagelijkse behoeften onder de vorm van :
                                    <ul>
                                        <li>een gratis voedselpakket, dat de leden van het Sint-Martinusfonds elke 2° donderdag van elke maand kunnen ophalen.
                                            Deze pakketten worden bedeeld per gezin waarbij de grootte van het pakket afhankelijk is van de grootte van het gezin.
                                        </li>
                                        <li>Gratis kleding voor dames, heren en kinderen en dit voor elk van de 4 seizoenen</li>
                                        <li>Gratis klein huishoudgerief en kleine meubels (indien voorradig)</li>
                                        <li>Huishoudtextiel zoals lakens, dekens, gordijnen, ...</li>
                                        <li>Gratis speelgoed</li>
                                        <li>Gratis schoenen voor dames, heren en kinderen (wat voorradig is).</li>
                                        <li>Boeken</li>
                                    </ul>
                                </p>
                            </div>
                            <div class="card-footer"></div>
                        </div>
                        <div class="card border ml-1">
                            <div class="card-body text-right">
                                <p>Anderzijds wil het Sint-Martinusfonds, door het voeren van discrete gesprekken en het geven van financieel en sociaal advies, helpen om de
                                    levensomstandigheden van behoeftige mensen te verbeteren.
                                </p>
                                <p>Ook is het de bedoeling om mensen door te verwijzen naar instellingen (zoals vb. OCMW) en
                                    professionele hulpverleners, die deze mensen dan verder kunnen begeleiden.
                                </p>
                            </div>
                            <div class="card-footer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <PageBreaker />
        <Gallery />
        <section id="home-content-divers">
            <hr>
            <div class="container pt-1 pb-5">
                <div class="row">
                    <div class="col-12">
                        <div class="card border mb-resp">
                            <div class="card-header text-white d-flex flex-column justify-content-center align-items-center">
                                <h2>Vrijwilligers - donaties</h2>
                            </div>
                            <div class="card-body">
                                <div class="row border-bottom my-3">
                                    <div class="col-md-9 p-3">
                                        <h5>Vrijwilligers</h5>
                                        <p class="pt-2">
                                            Onze deur van het Sint-Martinus Fonds (SMF) staat niet alleen open voor mensen in nood te ontvangen, maar ook voor mensen die hulp of ondersteuning wensen te bieden aan ons noodfonds.
                                            Indien u zich wenst aan te melden als vrijwilliger of indien u hier verder informatie wenst over te ontvangen, dan kan u steeds contact nemen met één van onze vaste medewerkers of met Raoul Verhaeven.
                                        </p>
                                    </div>
                                    <div class="col-md-3 d-none d-md-block p-3">
                                        <img src="@/assets/images/armoede_2.jpg" alt="" class="img-fluid rounded shadow img-1">
                                    </div>
                                </div>
                                <div class="row border-bottom my-3">
                                    <div class="col-md-4 d-none d-md-block p-3">
                                        <img src="@/assets/images/armoede_1.jpg" alt="" class="img-fluid rounded shadow img-2">
                                    </div>
                                    <div class="col-md-8 p-3">
                                        <h5 class="text-right">Giften & donaties</h5>
                                        <p class="pt-2 text-right">
                                            Wenst u ons fonds materieel te ondersteunen door ons iets van kledij, textiel, speelgoed, ... te schenken, dan kan dit uiteraard! We zorgen er voor dat alle donaties bij de juiste mensen terechtkomen zodat niets verloren gaat!
                                            Op deze manier krijgen niet alleen uw spullen een tweede leven en een tweede kans.
                                        </p>
                                        <p class="pt-2 text-right">
                                            Daarnaast bestaat er ook de mogelijkheid om ons financieel te steunen. Een centje op onze bankrekening zullen wij héél graag ontvangen, want we zullen wel altijd euro’s nodig hebben om onze acties te blijven ondersteunen.
                                            Donaties kunnen gestort worden op onze bankrekening : BE75 7370 2162 2151. Voor verder informatie en praktische afspraken kan u contact men met één van de vaste medewerkers of met Raoul Verhaeven.
                                        </p>
                                        <p class="pt-2 text-right">
                                            Onze contactgegevens kan u <router-link to="contact">hier</router-link> terugvinden.
                                        </p>
                                    </div>
                                </div>
                                <div class="row my-3">
                                    <p class="pt-5 text-center bold">
                                        In naam van mensen die weinig hebben danken wij jullie voor alle hulp die wij krijgen!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import TheBanner from '../components/layout/TheBanner'
import Gallery from '../components/content/gallery'
import PageBreaker from '../components/content/page-breaker'

export default {
    components: {
        TheBanner, Gallery, PageBreaker
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
.card-body {
    border: rgb(20, 29, 42);
}
.card-header {
    background: rgba(20, 29, 42, 0.8);
    height: 75px;
}

.card-footer {
    background: rgba(20, 29, 42, 0.8);
    height: 10px;
}

.divider {
    width: 50%;
}

.bold {
    font-weight: bold;
    font-size: 1.5rem;
}

.img-1 {
    transform: rotate(10deg);
}

.img-2 {
    transform: rotate(-10deg);
}

</style>
