<template>
    <section id="page-breaker" class="my-2">
        <div class="primary-overlay text-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center pt-5">
                        <h1 class="display-3">Sint-Martinus Fonds</h1>
                        <p class="d-none d-md-block">-- Omdat we mensen en menselijk zijn --</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

#page-breaker {
    position: relative;
    min-height: 200px;

}
#page-breaker .primary-overlay {
    background: rgba(117, 0, 29, 0.822);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

p {
    font-size: 1.2em;
    font-family: cursive;
}
</style>
