import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_ENV_BASE_URL

export default {
    async loadMoments(context, payload) {
        const response = await fetch(axios.defaults.baseURL + '/api/v1/moments')
        const responseData = await response.json()
        const responseRecords = await responseData.data

        if (!response.ok) {
            const error = new Error(responseData.message || 'Failed to fetch')
            console.log(error)
            throw error
        }

        const moments = []

        for (const key in responseRecords) {
            const moment = {
                id: key,
                date: responseRecords[key].date,
                time_from: responseRecords[key].time_from,
                time_to: responseRecords[key].time_to
            }
            moments.push(moment)
        }

        context.commit('setMoments', moments)
    },
    async addMoment(context, payload) {
        const moment = {
            date: payload.momentDate,
            time_from: payload.timeFrom,
            time_to: payload.timeTo
        }
        axios.post('/api/v1/management/moments', moment)
            .then(response => {
                context.commit('addMoment', response.data)
            }).catch(error => {
                console.log(error.response.data.message)
            })
    }
}
