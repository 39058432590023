import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/sanctum/Home.vue'
import Home from '../views/Home'
import store from '../store/index'

const routes = [

    { path: '/', name: 'Home', component: Home },
    {
        path: '/werking',
        name: 'Activities',
        component: () => import('../views/Activities.vue')
    },
    {
        path: '/nevenactiviteiten',
        name: 'AdditionalActivities',
        component: () => import('../views/AdditionalActivities.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue')
    },
    {
        path: '/ontstaan',
        name: 'OurHistory',
        component: () => import('../views/OurHistory.vue')
    },
    {
        path: '/doel',
        name: 'Mission',
        component: () => import('../views/Mission.vue')
    },
    {
        path: '/bedelingen',
        name: 'Moments',
        component: () => import('../views/Moments.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/sanctum/Login.vue'),
        meta: { requiresUnAuth: true }
    },
    {
        path: '/beheer',
        name: 'Beheer',
        component: () => import('../views/sanctum/Beheer.vue'),
        meta: { requiresAuth: true }
    },
    { path: '/:notFound(.*)', redirect: '/' }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(function (to, _, next) {
    if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
        next('/login')
    } else if (to.meta.requiresUnAuth && store.getters['auth/isAuthenticated']) {
        next('/beheer')
    } else {
        next()
    }
})

export default router
